import React from "react";
import { get } from "lodash";
import { useTheme } from "@chakra-ui/react";
import Table from "../../Table";
import Box from "../../base/Box";
import { STATUS } from "../../../utilities/constants";
import Icon from "../../Icon";
import Heading from "../../base/Heading";

const ServiceStatusTable = ({ data, title }) => {
  // const [loading, setLoading] = useState(true);
  const theme = useTheme();
  /* const totalJumpToPages = responsiveCss(
    TOTAL_JUMP_PAGES.MOBILE,
    TOTAL_JUMP_PAGES.DESKTOP
  ); */
  const tableHeader = [
    { label: "Unidad", value: "unit" },
    { label: "Posición", value: "position" },
    { label: "Mac Ethernet", value: "mac_eth" },
    { label: "ICC", value: "icc" },
    { label: "ISDN", value: "isdn" },
    { label: "Data availability", value: "data_available" },
    { label: "Last ping", value: "last_ping" },
    { label: "Estado", value: "status" },
  ];

  /* const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  }; */
  // setLoading(false);
  return (
    <Box>
      <Heading {...theme.variant.heading}>{title}</Heading>
      {/* {loading && <Spinner />} */}
      {
        <>
          <Table
            header={tableHeader}
            renderEmpty="Sin datos"
            rows={get(data, "data", []).map((node) => [
              get(node, "unit", "-"),
              /* <Box
                    color="mediumGreen"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {node.is_station === STATUS.ACTIVE ? (
                      <Icon
                        svg="is-station"
                        sx={{ color: "mediumGreen", width: "18px" }}
                      />
                    ) : (
                      <Icon svg="not-station" sx={{ width: "18px" }} />
                    )}
                  </Box>, */
              <Box
                as="a"
                color="mediumGreen"
                href={
                  node.is_station === STATUS.ACTIVE &&
                  `https://maps.google.com/?z=15&q=${[node.lat, node.lon].join(
                    ","
                  )}`
                }
                sx={{ display: "flex", justifyContent: "center" }}
                target="_blank"
              >
                {node.is_station === STATUS.ACTIVE && (
                  <Icon svg="map-pin" sx={{ width: "24px" }} />
                )}
              </Box>,
              get(node, "mac_eth", "-"),
              get(node, "icc", "-"),
              get(node, "isdn", "-"),
              get(node, "data_availability", "-"),
              get(node, "last_ping", "-"),
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {node.status === STATUS.ACTIVE ? (
                  <Icon
                    svg="node-status"
                    sx={{ color: "green", width: "18px" }}
                  />
                ) : (
                  <Icon
                    svg="node-status"
                    sx={{ color: "red", width: "18px" }}
                  />
                )}
              </Box>,
            ])}
            tableSx={{
              borderCollapse: "collapse",
              overflow: "visible",
            }}
          />
          {/* {data.total > 0 && (
            <DashboardPaginator
              buttonSx={{
                color: "primary",
              }}
              enableJumpToPage
              pageCriteria={{
                limit: 10,
                offset: 0,
              }}
              pageTotal={10}
              total={get(data, "totalNodes")}
              totalJumpToPages={totalJumpToPages}
            />
          )} */}
        </>
      }
    </Box>
  );
};

export default ServiceStatusTable;
