import React, { useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { get } from "lodash";
import { useToast } from "@chakra-ui/react";
import Route from "../../base/Route";
import Section from "../../base/Section";
import Box from "../../base/Box";
import getChartData from "./getChartData";
import TreeMap from "../../Charts/TreeMap";
import Dropdown from "../../base/Dropdown";
import { ALL_FLEET } from "../../../utilities/constants";
import { get as GET } from "../../../utilities/http";
import toastMessage from "../../../utilities/toastMessage";
import Button from "../../base/Button";
import getTableData from "./getTableData";
import ServiceStatusTable from "../ServiceStatusTable";

const ServiceStatus = () => {
  const toast = useToast();
  const [cityDropDownOptions, setCityDropDownOptions] = useState([]);
  const [systemDropDownOptions, setSystemDropDownOptions] = useState([]);
  const [subsystemDropDownOptions, setSubsystemDropDownOptions] = useState([]);
  const [lineDropDownOptions, setLineDropDownOptions] = useState([]);
  // const [unitData, setUnitData] = useState({});
  useEffect(async () => {
    try {
      const { data } = await GET("/listing-cities");
      const cityDropDownData = data.map((city) => ({
        label: `#${get(city, "city_id")} - ${get(city, "city")}`,
        value: get(city, "city_id"),
      }));
      setCityDropDownOptions([
        { label: "All", value: ALL_FLEET },
        ...cityDropDownData,
      ]);
    } catch (e) {
      toastMessage({ message: e.message, toast, type: "error" });
    }
  }, []);
  const gap = 4;
  const colStyled = {
    flex: 1,
    fontSize: 1,
    maxWidth: ["100%", null, null, "50%"],
    mb: gap,
    pr: gap,
  };
  const unitArray = {
    units: [
      {
        enabled: 1,
        is_station: 1,
        lat: "",
        lon: "",
        offNodes: [
          {
            data_availability: "node9 data",
            icc: "node9 icc",
            isdn: "node9 isdn",
            last_ping: "node9 ping",
            mac_eth: "node9 mac",
            name: "node 9",
            node_id: 9,
            status: 0,
          },
          {
            data_availability: "node10 data",
            icc: "node10 icc",
            isdn: "node10 isdn",
            last_ping: "node10 ping",
            mac_eth: "node10 mac",
            name: "node 10",
            node_id: 10,
            status: 0,
          },
          {
            data_availability: "node11 data",
            icc: "node11 icc",
            isdn: "node11 isdn",
            last_ping: "node11 ping",
            mac_eth: "node11 mac",
            name: "node 11",
            node_id: 11,
            status: 0,
          },
          {
            data_availability: "node12 data",
            icc: "node12 icc",
            isdn: "node12 isdn",
            last_ping: "node12 ping",
            mac_eth: "node12 mac",
            name: "node 12",
            node_id: 12,
            status: 0,
          },
          {
            data_availability: "node13 data",
            icc: "node13 icc",
            isdn: "node13 isdn",
            last_ping: "node13 ping",
            mac_eth: "node13 mac",
            name: "node 13",
            node_id: 13,
            status: 0,
          },
          {
            data_availability: "node14 data",
            icc: "node14 icc",
            isdn: "node14 isdn",
            last_ping: "node14 ping",
            mac_eth: "node14 mac",
            name: "node 14",
            node_id: 14,
            status: 0,
          },
          {
            data_availability: "node15 data",
            icc: "node15 icc",
            isdn: "node15 isdn",
            last_ping: "node15 ping",
            mac_eth: "node15 mac",
            name: "node 15",
            node_id: 15,
            status: 0,
          },
        ],
        onNodes: [
          {
            data_availability: "node1 data",
            icc: "node1 icc",
            isdn: "node1 isdn",
            last_ping: "node1 ping",
            mac_eth: "node1 mac",
            name: "node 1",
            node_id: 1,
            status: 1,
          },
          {
            data_availability: "node2 data",
            icc: "node2 icc",
            isdn: "node2 isdn",
            last_ping: "node2 ping",
            mac_eth: "node2 mac",
            name: "node 2",
            node_id: 2,
            status: 1,
          },
          {
            data_availability: "node3 data",
            icc: "node3 icc",
            isdn: "node3 isdn",
            last_ping: "node3 ping",
            mac_eth: "node3 mac",
            name: "node 3",
            node_id: 3,
            status: 1,
          },
          {
            data_availability: "node4 data",
            icc: "node4 icc",
            isdn: "node4 isdn",
            last_ping: "node4 ping",
            mac_eth: "node4 mac",
            name: "node 4",
            node_id: 4,
            status: 1,
          },
          {
            data_availability: "node5 data",
            icc: "node5 icc",
            isdn: "node5 isdn",
            last_ping: "node5 ping",
            mac_eth: "node5 mac",
            name: "node 5",
            node_id: 5,
            status: 1,
          },
          {
            data_availability: "node6 data",
            icc: "node6 icc",
            isdn: "node6 isdn",
            last_ping: "node6 ping",
            mac_eth: "node6 mac",
            name: "node 6",
            node_id: 6,
            status: 1,
          },
          {
            data_availability: "node7 data",
            icc: "node7 icc",
            isdn: "node7 isdn",
            last_ping: "node7 ping",
            mac_eth: "node7 mac",
            name: "node 7",
            node_id: 7,
            status: 1,
          },
          {
            data_availability: "node8 data",
            icc: "node8 icc",
            isdn: "node8 isdn",
            last_ping: "node8 ping",
            mac_eth: "node8 mac",
            name: "node 8",
            node_id: 8,
            status: 1,
          },
        ],
        totalNodes: 15,
        totalOffNodes: 7,
        totalOnNodes: 8,
        unit: "unit 1",
        unit_id: 1,
      },
      {
        enabled: 1,
        is_station: 1,
        lat: "",
        lon: "",
        offNodes: [
          {
            data_availability: "node16 data",
            icc: "node16 icc",
            isdn: "node16 isdn",
            last_ping: "node16 ping",
            mac_eth: "node16 mac",
            name: "node 16",
            node_id: 16,
            status: 0,
          },
          {
            data_availability: "node17 data",
            icc: "node17 icc",
            isdn: "node17 isdn",
            last_ping: "node17 ping",
            mac_eth: "node17 mac",
            name: "node 17",
            node_id: 17,
            status: 0,
          },
          {
            data_availability: "node18 data",
            icc: "node18 icc",
            isdn: "node18 isdn",
            last_ping: "node18 ping",
            mac_eth: "node18 mac",
            name: "node 18",
            node_id: 18,
            status: 0,
          },
          {
            data_availability: "node19 data",
            icc: "node19 icc",
            isdn: "node19 isdn",
            last_ping: "node19 ping",
            mac_eth: "node19 mac",
            name: "node 19",
            node_id: 19,
            status: 0,
          },
        ],
        onNodes: [
          {
            data_availability: "node20 data",
            icc: "node20 icc",
            isdn: "node20 isdn",
            last_ping: "node20 ping",
            mac_eth: "node20 mac",
            name: "node 20",
            node_id: 20,
            status: 1,
          },
          {
            data_availability: "node21 data",
            icc: "node21 icc",
            isdn: "node21 isdn",
            last_ping: "node21 ping",
            mac_eth: "node21 mac",
            name: "node 21",
            node_id: 21,
            status: 1,
          },
          {
            data_availability: "node22 data",
            icc: "node22 icc",
            isdn: "node22 isdn",
            last_ping: "node22 ping",
            mac_eth: "node22 mac",
            name: "node 22",
            node_id: 22,
            status: 1,
          },
          {
            data_availability: "node23 data",
            icc: "node23 icc",
            isdn: "node23 isdn",
            last_ping: "node23 ping",
            mac_eth: "node23 mac",
            name: "node 23",
            node_id: 23,
            status: 1,
          },
          {
            data_availability: "node24 data",
            icc: "node24 icc",
            isdn: "node24 isdn",
            last_ping: "node24 ping",
            mac_eth: "node24 mac",
            name: "node 24",
            node_id: 24,
            status: 1,
          },
          {
            data_availability: "node54 data",
            icc: "node25 icc",
            isdn: "node25 isdn",
            last_ping: "node25 ping",
            mac_eth: "node25 mac",
            name: "node 25",
            node_id: 25,
            status: 1,
          },
          {
            data_availability: "node26 data",
            icc: "node26 icc",
            isdn: "node26 isdn",
            last_ping: "node26 ping",
            mac_eth: "node26 mac",
            name: "node 26",
            node_id: 26,
            status: 1,
          },
          {
            data_availability: "node27 data",
            icc: "node27 icc",
            isdn: "node27 isdn",
            last_ping: "node27 ping",
            mac_eth: "node27 mac",
            name: "node 27",
            node_id: 27,
            status: 1,
          },
        ],
        totalNodes: 12,
        totalOffNodes: 4,
        totalOnNodes: 8,
        unit: "unit 2",
        unit_id: 2,
      },
      {
        enabled: 1,
        is_station: 1,
        lat: "",
        lon: "",
        offNodes: [
          {
            data_availability: "node28 data",
            icc: "node28 icc",
            isdn: "node28 isdn",
            last_ping: "node28 ping",
            mac_eth: "node28 mac",
            name: "node 28",
            node_id: 28,
            status: 0,
          },
          {
            data_availability: "node29 data",
            icc: "node29 icc",
            isdn: "node29 isdn",
            last_ping: "node29 ping",
            mac_eth: "node29 mac",
            name: "node 29",
            node_id: 29,
            status: 0,
          },
          {
            data_availability: "node30 data",
            icc: "node30 icc",
            isdn: "node30 isdn",
            last_ping: "node30 ping",
            mac_eth: "node30 mac",
            name: "node 30",
            node_id: 30,
            status: 0,
          },
          {
            data_availability: "node31 data",
            icc: "node31 icc",
            isdn: "node31 isdn",
            last_ping: "node31 ping",
            mac_eth: "node31 mac",
            name: "node 31",
            node_id: 31,
            status: 0,
          },
        ],
        onNodes: [
          {
            data_availability: "node32 data",
            icc: "node32 icc",
            isdn: "node32 isdn",
            last_ping: "node32 ping",
            mac_eth: "node32 mac",
            name: "node 32",
            node_id: 32,
            status: 1,
          },
          {
            data_availability: "node33 data",
            icc: "node33 icc",
            isdn: "node33 isdn",
            last_ping: "node33 ping",
            mac_eth: "node33 mac",
            name: "node 33",
            node_id: 33,
            status: 1,
          },
          {
            data_availability: "node34 data",
            icc: "node34 icc",
            isdn: "node34 isdn",
            last_ping: "node34 ping",
            mac_eth: "node34 mac",
            name: "node 34",
            node_id: 34,
            status: 1,
          },
          {
            data_availability: "node35 data",
            icc: "node35 icc",
            isdn: "node35 isdn",
            last_ping: "node35 ping",
            mac_eth: "node35 mac",
            name: "node 35",
            node_id: 35,
            status: 1,
          },
          {
            data_availability: "node36 data",
            icc: "node36 icc",
            isdn: "node36 isdn",
            last_ping: "node36 ping",
            mac_eth: "node36 mac",
            name: "node 36",
            node_id: 36,
            status: 1,
          },
          {
            data_availability: "node37 data",
            icc: "node37 icc",
            isdn: "node37 isdn",
            last_ping: "node37 ping",
            mac_eth: "node37 mac",
            name: "node 37",
            node_id: 37,
            status: 1,
          },
          {
            data_availability: "node38 data",
            icc: "node38 icc",
            isdn: "node38 isdn",
            last_ping: "node38 ping",
            mac_eth: "node38 mac",
            name: "node 38",
            node_id: 38,
            status: 1,
          },
          {
            data_availability: "node39 data",
            icc: "node39 icc",
            isdn: "node39 isdn",
            last_ping: "node39 ping",
            mac_eth: "node39 mac",
            name: "node 39",
            node_id: 39,
            status: 1,
          },
          {
            data_availability: "node40 data",
            icc: "node40 icc",
            isdn: "node40 isdn",
            last_ping: "node40 ping",
            mac_eth: "node40 mac",
            name: "node 40",
            node_id: 40,
            status: 1,
          },
        ],
        totalNodes: 13,
        totalOffNodes: 4,
        totalOnNodes: 9,
        unit: "unit 3",
        unit_id: 3,
      },
      {
        enabled: 1,
        is_station: 1,
        lat: "",
        lon: "",
        offNodes: [
          {
            data_availability: "node41 data",
            icc: "node41 icc",
            isdn: "node41 isdn",
            last_ping: "node41 ping",
            mac_eth: "node41 mac",
            name: "node 41",
            node_id: 41,
            status: 0,
          },
          {
            data_availability: "node42 data",
            icc: "node42 icc",
            isdn: "node42 isdn",
            last_ping: "node42 ping",
            mac_eth: "node42 mac",
            name: "node 42",
            node_id: 42,
            status: 0,
          },
          {
            data_availability: "node43 data",
            icc: "node43 icc",
            isdn: "node43 isdn",
            last_ping: "node43 ping",
            mac_eth: "node43 mac",
            name: "node 43",
            node_id: 43,
            status: 0,
          },
          {
            data_availability: "node44 data",
            icc: "node44 icc",
            isdn: "node44 isdn",
            last_ping: "node44 ping",
            mac_eth: "node44 mac",
            name: "node 44",
            node_id: 44,
            status: 0,
          },
          {
            data_availability: "node45 data",
            icc: "node45 icc",
            isdn: "node45 isdn",
            last_ping: "node45 ping",
            mac_eth: "node45 mac",
            name: "node 45",
            node_id: 45,
            status: 0,
          },
          {
            data_availability: "node46 data",
            icc: "node46 icc",
            isdn: "node46 isdn",
            last_ping: "node46 ping",
            mac_eth: "node46 mac",
            name: "node 46",
            node_id: 46,
            status: 0,
          },
        ],
        onNodes: [
          {
            data_availability: "node47 data",
            icc: "node47 icc",
            isdn: "node47 isdn",
            last_ping: "node47 ping",
            mac_eth: "node47 mac",
            name: "node 47",
            node_id: 47,
            status: 1,
          },
          {
            data_availability: "node48 data",
            icc: "node48 icc",
            isdn: "node48 isdn",
            last_ping: "node48 ping",
            mac_eth: "node48 mac",
            name: "node 48",
            node_id: 48,
            status: 1,
          },
          {
            data_availability: "node49 data",
            icc: "node49 icc",
            isdn: "node49 isdn",
            last_ping: "node49 ping",
            mac_eth: "node49 mac",
            name: "node 49",
            node_id: 49,
            status: 1,
          },
          {
            data_availability: "node50 data",
            icc: "node50 icc",
            isdn: "node50 isdn",
            last_ping: "node50 ping",
            mac_eth: "node50 mac",
            name: "node 50",
            node_id: 50,
            status: 1,
          },
          {
            data_availability: "node51 data",
            icc: "node51 icc",
            isdn: "node51 isdn",
            last_ping: "node51 ping",
            mac_eth: "node51 mac",
            name: "node 51",
            node_id: 51,
            status: 1,
          },
          {
            data_availability: "node52 data",
            icc: "node52 icc",
            isdn: "node52 isdn",
            last_ping: "node52 ping",
            mac_eth: "node52 mac",
            name: "node 52",
            node_id: 52,
            status: 1,
          },
          {
            data_availability: "node53 data",
            icc: "node53 icc",
            isdn: "node53 isdn",
            last_ping: "node53 ping",
            mac_eth: "node53 mac",
            name: "node 53",
            node_id: 53,
            status: 1,
          },
          {
            data_availability: "node54 data",
            icc: "node54 icc",
            isdn: "node54 isdn",
            last_ping: "node54 ping",
            mac_eth: "node54 mac",
            name: "node 54",
            node_id: 54,
            status: 1,
          },
          {
            data_availability: "node55 data",
            icc: "node55 icc",
            isdn: "node55 isdn",
            last_ping: "node55 ping",
            mac_eth: "node55 mac",
            name: "node 55",
            node_id: 55,
            status: 1,
          },
          {
            data_availability: "node56 data",
            icc: "node56 icc",
            isdn: "node56 isdn",
            last_ping: "node56 ping",
            mac_eth: "node56 mac",
            name: "node 56",
            node_id: 56,
            status: 1,
          },
        ],
        totalNodes: 16,
        totalOffNodes: 6,
        totalOnNodes: 10,
        unit: "unit 4",
        unit_id: 4,
      },
    ],
  };
  const chartData = getChartData(unitArray.units);
  const tableData = getTableData(unitArray.units);
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box>
          <FinalForm
            onSubmit={async (values) => {
              const formValues = {
                city_id: values.city_id,
                line_id: values.line_id,
                subsystem_id: values.subsystem_id,
                system_id: values.system_id,
              };
              console.log("---valuess---", formValues);
              /* try {
                const searchParams = stringify({
                  filters: stringify(formValues),
                  page: stringify({ offset: 0, pageSize: 10 }),
                });
                const data = await GET(
                  `/listing-unit?${searchParams ? "?" : ""}${searchParams}`
                );
                if (data) {
                  const filteredUnitData = get(data, "data", []);
                  setUnitData(filteredUnitData);
                  // setLoading(false);
                }
              } catch (e) {
                // setLoading(false);
                toastMessage({ message: e.message, toast, type: "error" });
              } */
            }}
            render={(formContext) => (
              <form onSubmit={formContext.handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", mb: 8 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      mb: 2,
                    }}
                  >
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="city_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-system?city_id=${value}`
                          );
                          const systemDropDownData = get(data, "data", []).map(
                            (system) => ({
                              label: `#${get(system, "system_id")} - ${get(
                                system,
                                "system"
                              )}`,
                              value: get(system, "system_id"),
                            })
                          );
                          setSystemDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...systemDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={cityDropDownOptions}
                      placeholder="Select City"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="system_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-subsystem?system_id=${value}`
                          );
                          const subsystemDropDownData = get(
                            data,
                            "data",
                            []
                          ).map((subsystem) => ({
                            label: `#${get(subsystem, "subsystem_id")} - ${get(
                              subsystem,
                              "subsystem"
                            )}`,
                            value: get(subsystem, "subsystem_id"),
                          }));
                          setSubsystemDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...subsystemDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={systemDropDownOptions}
                      placeholder="Select System"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="subsystem_id"
                      onChange={async (value) => {
                        try {
                          const data = await GET(
                            `/listing-line?subsystem_id=${value}`
                          );
                          const lineDropDownData = get(data, "data", []).map(
                            (line) => ({
                              label: `#${get(line, "line_id")} - ${get(
                                line,
                                "line"
                              )}`,
                              value: get(line, "line_id"),
                            })
                          );
                          setLineDropDownOptions([
                            { label: "All", value: ALL_FLEET },
                            ...lineDropDownData,
                          ]);
                        } catch (e) {
                          toastMessage({
                            message: e.message,
                            toast,
                            type: "error",
                          });
                        }
                      }}
                      options={subsystemDropDownOptions}
                      placeholder="Select Subsystem"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                    <Box
                      as={Field}
                      component={Dropdown}
                      name="line_id"
                      options={lineDropDownOptions}
                      placeholder="Select Line"
                      sx={{ maxWidth: "100%" }}
                      wrapperSx={{ ...colStyled }}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-end",
                      display: "flex",
                      flexDirection: ["column", null, "row"],
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Location>
                      {({ location: { pathname } }) => (
                          <Button
                              onClick={() => {
                                formContext.form.reset({
                                  city_id: "",
                                  line_id: "",
                                  subsystem_id: "",
                                  system_id: "",
                                });
                                navigate(pathname);
                              }}
                              sx={{ maxWidth: "100px" }}
                              variant="primary"
                          >
                            Reset
                          </Button>
                      )}
                    </Location> */}
                    <Button
                      disabled={formContext.submitting}
                      submitting={formContext.submitting}
                      sx={{ maxWidth: "100px", ml: 3 }}
                      variant="primary"
                    >
                      Mostrar
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            ml: 2,
          }}
        >
          <TreeMap chartData={chartData} chartTitle="Station units" />
          <TreeMap chartData={chartData} chartTitle="Bus units" />
        </Box>
        <Box sx={{ my: 5 }}>
          <ServiceStatusTable data={tableData} title={"Station Units"} />
        </Box>
      </Section>
    </Route>
  );
};

export default ServiceStatus;
