const getTableData = (units) => {
  const data = [];
  let totalNodes = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const unit of units) {
    for (const node of unit.onNodes) {
      data.push({
        ...node,
        is_station: unit.is_station,
        lat: unit.lat,
        lon: unit.lon,
        unit: unit.unit,
      });
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const node of unit.offNodes) {
      data.push({
        ...node,
        is_station: unit.is_station,
        lat: unit.lat,
        lon: unit.lon,
        unit: unit.unit,
      });
    }
    totalNodes += unit.totalNodes;
  }

  return { data, totalNodes };
};
export default getTableData;
