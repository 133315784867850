import PropTypes from "prop-types";
import React from "react";
import Box from "../Box";

const InputWrapper = ({
  children,
  customCheckValue,
  dataCy,
  disabled,
  errorSx,
  validationIcon,
  htmlFor,
  input,
  inputWrapperSx,
  label,
  labelSx,
  meta,
  setValidateError,
  subtext,
  wrapperSx,
  clearButton,
  onClickClearButton,
  ...rest
}) => {
  const error = meta.error && meta.touched;
  let below = null;
  if (!error) {
    setValidateError("");
  }
  if (error) {
    setValidateError(meta.error);
    below = customCheckValue ? null : (
      <Box
        sx={{
          color: "error",
          fontSize: 0,
          ml: 2,
          mt: 0,
          position: "absolute",
          ...errorSx,
        }}
      >
        {meta.error}
      </Box>
    );
  } else if (subtext) {
    below = (
      <Box fontSize={2} mt={1}>
        {subtext}
      </Box>
    );
  }
  const topElementSx = {
    "&:first-of-type": { mt: 0 },
    flex: 1,
    opacity: disabled ? "0.4" : null,
    pointerEvents: disabled ? "none" : null,
    position: "relative",
    ...wrapperSx,
  };
  return (
    <Box data-cy={dataCy} {...topElementSx}>
      {label && (
        <Box
          as="label"
          htmlFor={htmlFor || input.name}
          sx={{
            color: "text.primary",
            display: "block",
            fontSize: 1,
            fontWeight: "regular",
            letterSpacing: 0,
            mb: 2,
            textTransform: "uppercase",
            ...labelSx,
          }}
        >
          {label}
        </Box>
      )}
      <Box sx={{ position: "relative", ...inputWrapperSx }}>
        {children({
          disabled,
          error,
          ...rest,
        })}
        {validationIcon && meta.touched && (
          <Box
            sx={{
              alignItems: "center",
              bg: "white",
              color: `${error ? "error" : "success"}`,
              display: "flex",
              height: "calc(100% - 6px)",
              m: "2px",
              position: "absolute",
              right: "0",
              top: "0",
            }}
          >
            {validationIcon(error)}
          </Box>
        )}
      </Box>
      {below}
    </Box>
  );
};

InputWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  clearButton: PropTypes.bool,
  customCheckValue: PropTypes.bool,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  errorSx: PropTypes.shape({}),
  htmlFor: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  inputWrapperSx: PropTypes.shape({}),
  label: PropTypes.node,
  labelSx: PropTypes.shape({}),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
  onClickClearButton: PropTypes.func,
  setValidateError: PropTypes.func,
  subtext: PropTypes.node,
  validationIcon: PropTypes.func,
  wrapperSx: PropTypes.shape({}),
};

InputWrapper.defaultProps = {
  clearButton: false,
  customCheckValue: false,
  dataCy: null,
  disabled: false,
  errorSx: {},
  htmlFor: null,
  inputWrapperSx: {},
  label: null,
  labelSx: {},
  onClickClearButton: () => {},
  setValidateError: () => null,
  subtext: null,
  validationIcon: () => null,
  wrapperSx: {},
};

export default InputWrapper;
