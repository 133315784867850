import React from "react";
import Highcharts from "highcharts";
import HighchartsData from "highcharts/modules/data";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsTreeChart from "highcharts/modules/treemap";
import HighchartsReact from "highcharts-react-official";
import Box from "../../base/Box";

if (typeof Highcharts === "object") {
  HighchartsData(Highcharts);
  HighchartsHeatmap(Highcharts);
  HighchartsTreeChart(Highcharts);
  HighchartsExporting(Highcharts);
}

const TreeMap = ({ chartData, chartTitle }) => {
  const chartOptions = {
    credits: false,
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
      },
    },
    series: [
      {
        allowDrillToNode: true,
        animation: false,
        data: chartData,
        dataLabels: {
          enabled: false,
        },
        layoutAlgorithm: "squarified",
        levelIsConstant: false,
        levels: [
          {
            borderWidth: 5,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "13px",
              },
            },
            level: 1,
          },
        ],
        type: "treemap",
        /* [
          {
            id: "unit_1",
            name: "unit1",
            value: 15,
          },
          {
            id: "unit_2",
            name: "unit2",
            value: 20,
          },
          {
            id: "unit_1_on",
            name: "On devices",
            value: 7,
            parent: "unit_1",
            color: "red",
          },
          {
            id: "unit_1_off",
            name: "Off devices",
            value: 8,
            parent: "unit_1",
            color: "green",
          },
          {
            id: "unit_2_on",
            name: "On devices",
            value: 7,
            parent: "unit_2",
            color: "red",
          },
          {
            id: "unit_2_off",
            name: "Off devices",
            value: 13,
            parent: "unit_2",
            color: "green",
          },
          {
            id: "node_1",
            name: "node1 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_2",
            name: "node2 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_3",
            name: "node3 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_4",
            name: "node4 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_5",
            name: "node5 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_6",
            name: "node6 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_7",
            name: "node7 mac",
            parent: "unit_1_on",
            value: 1,
          },
          {
            id: "node_8",
            name: "node8 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_9",
            name: "node9 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_10",
            name: "node10 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_11",
            name: "node11 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_12",
            name: "node12 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_13",
            name: "node13 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_14",
            name: "node14 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_15",
            name: "node15 mac",
            parent: "unit_1_off",
            value: 1,
          },
          {
            id: "node_16",
            name: "node16 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_17",
            name: "node17 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_18",
            name: "node18 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_19",
            name: "node19 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_20",
            name: "node20 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_21",
            name: "node21 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_22",
            name: "node22 mac",
            parent: "unit_2_on",
            value: 1,
          },
          {
            id: "node_23",
            name: "node23 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_24",
            name: "node24 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_25",
            name: "node25 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_26",
            name: "node26 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_27",
            name: "node27 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_28",
            name: "node28 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_29",
            name: "node29 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_30",
            name: "node30 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_31",
            name: "node31 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_32",
            name: "node32 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_33",
            name: "node33 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_34",
            name: "node34 mac",
            parent: "unit_2_off",
            value: 1,
          },
          {
            id: "node_35",
            name: "node35 mac",
            parent: "unit_2_off",
            value: 1,
          },
        ], */
      },
    ],
    title: {
      text: chartTitle,
    },
  };

  return (
    <Box sx={{ width: "545px" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Box>
  );
};

export default TreeMap;
